<template>
  <div class="content-container">
    <component
      :is="onPageKey.component"
      :refresh-key="refreshKey"
      v-bind="onPageKey"
    ></component>
  </div>
</template>

<script>
import { refreshTime } from '../config';
export default {
  //组件注册
  components: {},
  inject: ['pageTabs'],
  //组件传值
  props: {
    pageKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timer: null,
      refreshKey: Date.now(),
    };
  },
  //计算属性
  computed: {
    onPageKey() {
      const component = () =>
        import(
          `../${
            this.pageTabs.find((e) => e.value === this.pageKey)
              ? this.pageKey
              : this.pageTabs[0].value
          }/index.vue`
        );
      return {
        pageKey: this.pageKey,
        component,
      };
    },
  },
  //属性监听
  watch: {
    pageKey: {
      immediate: true,
      handler: 'timedRefresh',
    },
    $route: {
      deep: true,
      handler: function () {
        if (this.$route.name == 'login') {
          if (this.timer) clearInterval(this.timer);
        }
      },
    },
  },
  //DOM访问
  mounted() {},
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
  //保存方法
  methods: {
    timedRefresh() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.refreshKey = Date.now();
      }, refreshTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  flex: 1;
  padding: 0 18px 24px;
  overflow: hidden;
}
</style>
